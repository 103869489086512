<template>
    <section class="content">
        <div class="content__inner content__inner--sm">
            <header class="content__title">
                <h1>Disputes</h1>
                <div class="actions"></div>
            </header>
            <div class="card">
                <div class="card-header">
                    Search For Subscriber By Email
                </div>
                <div class="card-body">
                    <form @submit.prevent="search">
                        <div class="form-group">
                            <label for="email">Enter email address below</label>
                            <input type="email" v-model="email" class="form-control" id="email">
                        </div>
                        <div class="mybuttons">
                            <button class="btn btn-success" type="submit" :disabled="isLoading">
                                <loading v-if="isLoading" />
                                Search For Subscriber
                            </button>
                            <button class="btn btn-danger" type="button" :disabled="isLoading" @click="clear">
                                Clear
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            <div v-if="isView">

                <div class="card">
                    <div class="card-header">
                        Subscriber
                    </div>
                    <div class="listview listview--bordered">
                        <div class="listview__item" >
                            <div class="listview__content">
                                <div class="listview__heading">{{ theSubscriber.name }}</div>
                                <div class="listview__ellipse">{{ theSubscriber.email }}</div>
                                <div class="listview__ellipse">{{ theSubscriber.country }}</div>
                                <div class="listview__ellipse">{{ theSubscriber.created }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        Subscriptions
                    </div>
                    <table class="table table-sm" >
                        <thead>
                            <tr>
                                <th>Daily Guide</th>
                                <th>Daily Power</th>
                                <th>Daa Nkwa Abodoo</th>
                                <th>Daily Super Food</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> 
                                    <span class="badge" :class="mysubscriptions.dailyguide ? 'badge-success' : 'badge-danger' " @click="activate('dailyguide', mysubscriptions.dailyguide)">
                                        <i class="zmdi" :class=" mysubscriptions.dailyguide ? `zmdi-check` : `zmdi-close` "></i>
                                        {{ mysubscriptions.dailyguide ? 'Subscribed' : 'Not Subscribed' }}
                                    </span>
                                </td>
                                <td> 
                                    <span class="badge" :class="mysubscriptions.dailypower ? 'badge-success' : 'badge-danger' " @click="activate('dailypower', mysubscriptions.dailypower)">
                                        <i class="zmdi" :class=" mysubscriptions.dailypower ? `zmdi-check` : `zmdi-close` "></i>
                                        {{ mysubscriptions.dailypower ? 'Subscribed' : 'Not Subscribed' }}
                                    </span>
                                </td>
                                <td> 
                                    <span class="badge" :class="mysubscriptions.daankwaabodoo ? 'badge-success' : 'badge-danger' " @click="activate('daankwaabodoo', mysubscriptions.daankwaabodoo)">
                                        <i class="zmdi" :class=" mysubscriptions.daankwaabodoo ? `zmdi-check` : `zmdi-close` "></i>
                                        {{ mysubscriptions.daankwaabodoo ? 'Subscribed' : 'Not Subscribed' }}
                                    </span>
                                </td>
                                <td> 
                                    <span class="badge" :class="mysubscriptions.dailysuperfood ? 'badge-success' : 'badge-danger' " @click="activate('dailysuperfood', mysubscriptions.dailysuperfood)">
                                        <i class="zmdi" :class=" mysubscriptions.dailysuperfood ? `zmdi-check` : `zmdi-close` "></i>
                                        {{ mysubscriptions.dailysuperfood ? 'Subscribed' : 'Not Subscribed' }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="card">
                    <div class="card-header">
                        Subscription History
                    </div>
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <td>Status</td>
                                <td>Devotional</td>
                                <td>Currency</td>
                                <td>Reference</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(trans,i) in subsList" :key="i">
                                <td>{{ trans.created }}</td>
                                <td>{{ trans.status }}</td>
                                <td>{{ trans.devotional }}</td>
                                <td>{{ trans.currency }}</td>
                                <td>{{ trans.txref }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="notfound != ''">{{ notfound }}</div>
            
        </div>
    </section>
</template>

<script>
import firebase from "firebase/app"
import moment from "moment"

export default {
    data(){
        return {
            email: "",
            subscribers: [],
            theSubscriber: {},
            mysubscriptions: {},
            isLoading: false,
            isChecking: false,
            isView: false,
            isSubscriptions: false,
            isVerifying: false,
            subsList: [],
            notfound: "",
        }
    },
    methods: {
        async search(){
            this.isLoading = true
            const email = this.email
            const snapshot = await firebase.firestore().collection("subscribers").where("email", "==", email).get()
            if (!snapshot.empty) {
                snapshot.forEach(async subscriber => {
                    if (subscriber.exists) {
                        const data = subscriber.data()
                        data.created = moment(data.createdAt.toDate()).format("DD-MM-YYYY HH:mm:ss A")
                        this.theSubscriber = data
                        this.isView = true
                        const uid = data.uid
                        const year = moment().format("YYYY").toString()
                        const doc = await firebase.firestore()
                            .collection("subscribers")
                            .doc(uid)
                            .collection("devotionals")
                            .doc(year)
                            .get()
                        if (doc.exists) {
                            this.mysubscriptions = doc.data()
                            const subsList = []
                            const snaps = await firebase.firestore()
                                .collection("subscriptions")
                                .where("uid", "==", uid)
                                .orderBy("createdAt", "desc")
                                .get()
                            snaps.forEach(doc => {
                                const info = doc.data()
                                info.created =  moment(info.createdAt.toDate()).format("DD-MM-YYYY HH:mm:ss A")
                                subsList.push({ id: doc.id, ...info })
                            })
                            this.subsList = subsList
                        } else {
                            this.notfound = "No subscriptions this year"
                        }
                    }
                })
                this.isLoading = false
                this.isChecking = true
            } else {
                this.notfound = "Subscriber not found or email address is incorrect"
                this.isLoading = false
                this.isView = false
            }
        },
        clear(){
            this.notfound = ""
            this.email = ""
            this.isLoading = false
            this.isChecking = false
            this.isView = false
        },
        async activate(devotional, status){
            if (!status) {
                this.$swal({
                    title: "ACTIVATE",
                    text: "Are you sure you want to activate this?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then(async value => {
                    if (value) {
                        this.$toast.success(`You have successfully subscribed to ${devotional}`)
                        this.mysubscriptions[devotional] = true
                        const uid = this.theSubscriber.uid
                        const year = moment().format("YYYY").toString()
                        await firebase.firestore()
                            .collection("subscribers")
                            .doc(uid)
                            .collection("devotionals")
                            .doc(year)
                            .set(
                                {
                                    [devotional]: true
                                },
                                {
                                    merge: true
                                }
                            )
                    } else {
                        this.$toast.error(`Cancelled`)
                    }
                })
            } else {
                this.$swal(`You are already subscribed to: ${devotional}`);
            }

        },


        async alphanumeric(transactionRef){

            const verifyPaymentStatus = firebase.functions().httpsCallable("verifyPaymentStatus")
            const payload = { transactionRef }
            const response = await verifyPaymentStatus(payload)
            console.log(JSON.stringify(response))
            
            /*  
            
            if (transactionRef.match(/((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i)) {
                
                this.isVerifying = true

                const endpoint = "https://api.reddeonline.com/v1/status/" + transactionRef
                
                const AppID = "1239"
                
                const ApiKey = "vQTVtsJqE6bRwMmGZBjgnLndZHLwcUnThms77ZF9mm9avFcJjL"

                const Headers = {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "apikey": ApiKey,
                        "appid": AppID,
                    }
                }

                const response = await axios.get(endpoint, Headers)
                
                console.log(response.data);

                this.isVerifying = false


            } else {
                this.$toast.error("This is a PaySwitch Transaction")
            }
            */
        },

        isAlphanumeric(transactionRef){
            if (transactionRef.match(/((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i)) {
                return true
            } else {
                return false
            }
        },



    },
    components: {
        loading: () => import("@/components/Loaders/Button.vue"),
    }
}
</script>

<style scoped>
.mybuttons {
    display: flex;
}
.mybuttons button {
    margin-right: 10px;
}
</style>